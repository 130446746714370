<template>
	<div class="container-fluid text-center">
		<div class="row">
			<div class="col-12 text-center">
				<h1>Shiny Stories</h1>
			</div>
		</div>
		<div class="row">
			<div class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
				<div class="text-muted mb-3">If you&apos;re a new user, an account will be created for you</div>
				<div class="form-group">
					<input
						autofocus
						class="form-control"
						type="text"
						maxlength="10"
						placeholder="Username"
						v-model="username"
						v-on:keyup.enter="handleLogin"
					/>
				</div>
				<div class="form-group">
					<input
						class="form-control"
						type="password"
						minlength="6"
						maxlength="8"
						placeholder="Password"
						v-model="password"
						v-on:keyup.enter="handleLogin"
					/>
				</div>
			</div>
			<div class="col-12 mb-2 text-center text-danger" :class="{ invisible : errorText === 'none' }">
				{{ errorText }}
			</div>
		</div>
		<div class="row">
			<div class="col-6 offset-3 col-md-4 offset-md-4">
				<loading-button :click="handleLogin" :loading="loading" text="Login" />
			</div>
		</div>
	</div>
</template>

<script>
import { createNewUser, signInUser } from '../services/auth';
import LoadingButton from '../components/LoadingButton';

export default {
	components: { LoadingButton },
	data() {
		return {
			username: '',
			password: '',
			errorText: 'none',
			loading: false,
		}
	},
	methods: {
		handleLogin() {
			if (this.username.trim() === '' || this.password.trim() === '') {
				this.errorText = 'No credentials provided';
				return;
			}
			if (this.username.trim().length < 5 || this.username.trim().length > 10) {
				this.errorText = 'Username must be between 5 and 10 characters long';
				return;
			}
			if (this.password.trim().length < 6 || this.password.trim().length > 8) {
				this.errorText = 'Password must be between 6 and 8 characters long';
				return;
			}
			this.loading = true;
			createNewUser(this.username, this.password)
				.then(() => this.$router.replace('/'))
				.catch(({ code : createCode }) => {
				if (createCode === 'auth/email-already-in-use') {
					signInUser(this.username, this.password)
					.then(() => this.$router.replace('/'))
					.catch(() => {
						this.errorText = 'Invalid credentials';
					}).finally(() => {
						this.loading = false;
					});
				}
			});
		},
	}
}
</script>
<template>
	<button :type="type" @click="click()" class="loading-button btn" :class="`btn-${bsColor}`">
		<svg xmlns="http://www.w3.org/2000/svg"
		class="loading-icon"
		:class="{ 'd-none': !loading }"
		viewBox="0 0 110 45"
		width="30%"
		height="100%"
		role="presentation"
		>
			<g fill="#fff">
				<path d="M0,30a15,15 0 1,0 30,0a15,15 0 1,0 -30,0z">
					<animateMotion id="bounceone" path="M0,0l0,-25l0,25" begin="0s;bounceone.end+1.1s" dur="0.4s" />
				</path>
				<path d="M40,30a15,15 0 1,0 30,0a15,15 0 1,0 -30,0z">
					<animateMotion id="bouncetwo" path="M0,0l0,-25l0,25" begin="bounceone.begin+0.15s" dur="0.4s" />
				</path>
				<path d="M80,30a15,15 0 1,0 30,0a15,15 0 1,0 -30,0z">
					<animateMotion path="M0,0l0,-25l0,25" begin="bouncetwo.begin+0.15s" dur="0.4s" />
				</path>
			</g>
		</svg>
		<span :class="{ 'invisible': loading }">{{ text }}</span>
	</button>
</template>

<script>
export default {
	name: 'LoadingButton',
	props: {
		type: {
			type: String,
			default: 'button',
		},
		bsColor: {
			type: String,
			validator: (val) => ['primary', 'success', 'danger'].indexOf(val) !== -1,
			default: 'primary',
		},
		click: {
			type: Function,
		},
		text: {
			type: String,
			default: '',
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
	.loading-button {
		position: relative;
		transition: width 0.4s ease;

		&:active {
			transition: width 0.4s ease;
		}

		.loading-icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
</style>
